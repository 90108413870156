/* Tambahkan di file src/Dashboard.css */
.dashboard-container {
    padding: 20px;
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
}

.dashboard-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.dashboard-banner h2 {
    margin: 0;
    font-size: 24px;
}

.dashboard-banner .logo {
    width: 50px; /* Sesuaikan ukuran logo */
    height: auto;
}


.presensi-form {
    margin-top: 20px;
}

.presensi-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.presensi-form input, .presensi-form button {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    font-size: 16px;
}

.presensi-form button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.presensi-form button:hover {
    background-color: #45a049;
}

.error-message, .success-message {
    margin-top: 15px;
    font-size: 14px;
}

h2, h3 {
    font-size: 20px;
    margin: 15px 0;
}

/* Responsif untuk layar kecil */
@media (max-width: 600px) {
    .dashboard-container {
        padding: 10px;
    }

    .presensi-form input, .presensi-form button {
        font-size: 14px;
        padding: 8px;
    }
}
